const pdfToolsRoutes = [
  {
    path: "/pdftools",
    component: () => import("@/features/pdftools/views/Index.vue"),
    name: "PDFStart",
    meta: {
      requiresLoggedIn: true,
      title: "PDFTools",
      breadCrumb: "PDFTools",
    
    }, 
    children: [
      {
        path: "",
        name: "PDFOverview",
        component: () => import("@/features/pdftools/views/Overview.vue"),
        meta: {
          title: "Übersicht",
        },
      },
      {
        path: "merge",
        name: "PDFMerge",
        component: () => import("@/features/pdftools/views/Merge.vue"),
        meta: {
          title: "Zusammenführen",
          breadCrumb: "Zusammenführen",    
        },
      },
      {
        path: "convert",
        name: "PDFConvert",
        component: () => import("@/features/pdftools/views/Convert.vue"),
        meta: {
          title: "Konvertieren",
          breadCrumb: "Konvertieren",    
        },
      },
    ]

  },
];

export default pdfToolsRoutes;
